<template>
  <div>
    <title>SIM INVENTORY ~ REQUEST ITEM DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Request Item Data
        <br />
        <h4>The following is a list of request item data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Request Item Data</h3>
                <br /><br />
                <div>
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
              </div>
              <p></p>
              <div class="box-body">
                <div class="row">
                  <div class="col-md-5">
                    <label>Item Category</label>
                    <select2
                      :data="categorylist"
                      :value="valuecategory"
                      @update="updatecategory($event)"
                    ></select2>
                  </div>
                </div>
                <p></p><br>
                <div class="row">
                  <div class="col-md-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="searchreq"
                    />
                  </div>
                  <div class="col-md-1">
                    <button
                      @click="fetchData()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <select
                      class="form-control select2"
                      name="order"
                      id="order"
                      v-model="order"
                      @change="fetchData()"
                    >
                      <option value="ALL">--All--</option>
                      <option
                        v-for="listorder in orders"
                        :key="listorder.id"
                        :value="listorder.order_number"
                      >
                        {{ listorder.order_number }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Request</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(classdata, index) in list_paged" :key="index">
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>
                        <button
                          title="Request"
                          class="btn btn-info"
                          @click="addPO(classdata)"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button class="btn btn-info" @click="addPO(this.list_paged)">
                  Request
                </button>
              </div>
            </div>
            <div v-else class="box-body">
              <p class="text-center text-red">--Data not found--</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Item Request</h3>
              </div>
              <!-- /.box-header -->

              <div class="box-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="box-header with-border">
                      <i class="fa fa-save"></i>
                      <h3 class="box-title">Save Data</h3>
                    </div>
                    <!-- /.box-header -->
                    <!-- form start -->
                    <form role="form">
                      <div class="box-body">
                        <div class="form-group">
                          <label for="exampleInputEmail1">PO Number</label>
                          <input
                            type="text"
                            v-model="isidata.ponumber"
                            autocomplete="off"
                            class="form-control"
                            placeholder="PO Number"
                          />
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">PO Type</label>
                          <select class="form-control" v-model="isidata.typepo">
                            <option disabled>--Please Select--</option>
                            <option
                              v-for="datajenispo in jenispo"
                              :key="datajenispo.id"
                              :value="datajenispo.value"
                            >
                              {{ datajenispo.value }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Supplier</label>
                          <select2
                            :data="suplist"
                            :value="valuesup"
                            @update="updatesup($event)"
                          ></select2>
                          <!-- <select
                            class="form-control"
                            v-model="isidata.kodesupplier"
                            id="kdsup"
                          >
                            <option disabled>--Please Select--</option>
                            <option
                              v-for="listsupp in suplist"
                              :key="listsupp.kode_supplier"
                              :value="listsupp.kode_supplier"
                            >
                              {{ listsupp.nama_supplier }}
                            </option>
                          </select> -->
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <!-- general form elements -->
                    <div class="box-header with-border">
                      <i class="fa fa-save"></i>
                      <h3 class="box-title">Save Data</h3>
                    </div>
                    <!-- /.box-header -->
                    <!-- form start -->
                    <form role="form">
                      <div class="box-body">
                        <div class="form-group">
                          <label for="exampleInputEmail1">PO Date</label>
                          <input
                            type="date"
                            v-model="isidata.podate"
                            autocomplete="off"
                            class="form-control"
                            placeholder="PO Date"
                          />
                        </div>

                        <div class="form-group">
                          <label for="exampleInputEmail1">Notes</label>
                          <input
                            type="text"
                            v-model="isidata.notespo"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Notes PO Headers"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="box-body">
                <div class="row">
                  <div class="col-md-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-md-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-md-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table id="mydatapo" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <!-- <th>Extra</th>
                      <th>Qty PO</th>
                      <th>Notes</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Convertion</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_po"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <!-- <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>
                        <input
                          :id="'inpn-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.notespod"
                          :name="'nmn-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpp-' + classdata.id"
                          type="number"
                          placeholder="ex : 3500"
                          required=""
                          class="form-control"
                          v-model="classdata.pricepod"
                          :name="'nmp-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpu-' + classdata.id"
                          type="text"
                          class="form-control"
                          placeholder="ex : Rp"
                          required=""
                          v-model="classdata.unitpricepod"
                          :name="'nmu-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpc-' + classdata.id"
                          type="number"
                          class="form-control"
                          placeholder="ex : 1 / 2 / 3"
                          required=""
                          v-model="classdata.konversi"
                          :name="'nmc-' + classdata.id"
                        />
                      </td> -->
                    </tr>
                  </tbody>
                </table>

                <!-- <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div> -->
              </div>
            </div>
          </div>
          <button @click="saveData()" type="button" class="btn btn-primary">
            <i class="fa fa-save"></i>
            Save
          </button>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      isExist: true,
      isAdded: false,
      namesearch: "",
      searchreq: "",
      list_paged: [],
      list_po: [],
      order: "ALL",
      orders: [],
      suplist: [],
      categorylist: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      valuesup: "",
      valuecategory: "",
      pageSizes: [10, 50, 100, 500],
      isidata: {
        ponumber: "",
        kodesupplier: "",
        notespo: "",
        podate: "",
        kodebarang: "",
        namabarang: "",
        typepo: "",
        unit: "",
      },
      classdata: {
        notespod: "",
        pricepod: "",
        unitpricepod: "",
        konversi: "",
      },
      jenispo: [
        { value: "Material", id: 1 },
        { value: "Auxiliary Raw Material", id: 2 },
        { value: "General", id: 3 },
      ],
    };
  },
  created() {
    this.fetchData();
    this.getOrder();
    this.getnow();
    this.getSupplierList();
    this.getItemCategoryList()
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.podate = tahun + "-" + month + "-" + tgl;
    },
    updatecategory(value){
      this.valuecategory = value
    },
    async getItemCategoryList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getalldatakategori_barang?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.categorylist = resp.data.data;
          this.categorylist.forEach((item) => {
            item.value = item.kategori;
            item.label = item.kategori;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    updatesup(value) {
      this.valuesup = value;
    },
    async getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    saveData() {
      const kode = this.isidata.ponumber;
      const kodesup = this.valuesup;
      const notespo = this.isidata.notespo;
      const jenpo = this.isidata.typepo;
      const podate = this.isidata.podate;
      const kodeuser = sessionStorage.getItem("kodeuser");
      const tokenlog = sessionStorage.getItem("token");
      const datagetgridpacklist = JSON.parse(
        localStorage.getItem("dtpoureq") || "[]"
      );

      /* const paramdata = {
        po_number: kode,
        kode_supplier: kodesup,
        notes: notespo,
        po_date: podate,
        kode_user: kodeuser,
        jenis_po: jenpo,
      }; */

      let paramdata = new FormData();

      paramdata.append("po_number", kode);
      paramdata.append("kode_supplier", kodesup);
      paramdata.append("notes", notespo);
      paramdata.append("po_date", podate);
      paramdata.append("kode_user", kodeuser);
      paramdata.append("jenis_po", jenpo);

      console.log(paramdata);

      const urlAPIsavedata = this.$apiurl + "po_header/savepo_header";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlog,
      };
      axios
        .post(urlAPIsavedata, paramdata, { headers })
        .then(() => {
          for (let i in datagetgridpacklist) {
            const paramdatadetail = {
              po_number: kode,
              kode_barang: datagetgridpacklist[i].kode_barang,
              notes: document.getElementById(
                "inpn-" + datagetgridpacklist[i].id
              ).value,
              qty: datagetgridpacklist[i].qty,
              price: document.getElementById(
                "inpp-" + datagetgridpacklist[i].id
              ).value,
              unit_price: document.getElementById(
                "inpu-" + datagetgridpacklist[i].id
              ).value,
              unit: datagetgridpacklist[i].unit,
              qty_po: datagetgridpacklist[i].qty,
              unit_po: datagetgridpacklist[i].unit,
              konversi: document.getElementById(
                "inpc-" + datagetgridpacklist[i].id
              ).value,
              kode_user: kodeuser,
            };
            const tokenlogin = sessionStorage.getItem("token");
            const urlAPIsavedatadet = this.$apiurl + "po_detail/savepo_detail";
            const headers = {
              Accept: "application/json",
              Authorization: tokenlogin,
            };

            if (
              Number(paramdatadetail.qty) > 0 &&
              Number(paramdatadetail.price) > 0 &&
              paramdatadetail.unit_price != "" &&
              Number(paramdatadetail.qty_po) > 0 &&
              paramdatadetail.unit_po != "" &&
              Number(paramdatadetail.konversi) > 0
            ) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then(() => {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data successfully",
                    showConfirmButton: false,
                  });
                  this.loading = false;
                })
                .catch((err) => {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                    showConfirmButton: false,
                  });
                  console.log(err);
                  this.loading = false;
                });
            }
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err,
            showConfirmButton: false,
          });
          this.loading = false;
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/po_header/getalldatapo_header?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "packing_list/getallv_temp_request?length=" +
        this.pageSize +
        "&cari=" +
        this.searchreq +
        "&order_number=" +
        this.order +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            console.log(resp.data);
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    getOrder() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "buyer_order/getdaftar_buyer_order_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isAdded = false;
          } else {
            this.isAdded = true;
            this.orders = resp.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/po_header/getalldatapo_header?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "packing_list/getallv_temp_request?cari=" +
        this.namesearch +
        ";" +
        this.cari +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/po_header/hapuspo_header/" + id;
          const urlAPIbrgdel =
            this.$apiurl + "packing_list/hapuspacking_list/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully",
                }).then(function () {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed",
                }).then(function () {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    addPO(data) {
      // console.log(Number(data.length));
      if (this.list_po.includes(data)) {
        console.log("data sudah ada");
      } else {
        if (data.length > 1) {
          this.list_po = this.list_paged;
          localStorage.setItem("dtpoureq", JSON.stringify(this.list_po));
        } else {
          this.list_po = this.list_po.concat(data);
          localStorage.setItem("dtpoureq", JSON.stringify(this.list_po));
        }
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
